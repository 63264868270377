import React from 'react';
import Bio from '../components/Bio';
import SEO from '../components/SEO';
import Layout from '../components/Layout';
import Footer from '../components/Footer';
import get from 'lodash/get';
import { graphql } from 'gatsby';

class NotFoundPage extends React.Component {
  render() {
    const siteTitle = get(this.props, 'data.site.siteMetadata.title');

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO lang={'en'} />
        <aside>
          <Bio langKey={'en'} />
        </aside>
        <main>
          <h1>Not Found</h1>
          <p>I haven’t written this post yet.</p>
          <p>
            <br></br>
          </p>
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/aBr2kKAHN6M"
            frameborder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullscreen
          />
        </main>
        <Footer />
      </Layout>
    );
  }
}

export const pageQuery = graphql`
  query NotFoundPageSiteData {
    site {
      siteMetadata {
        title
      }
    }
  }
`;

export default NotFoundPage;
