import React from 'react';
import profilePic from '../assets/profile-pic.jpg';
import { rhythm } from '../utils/typography';
import { languageTexts } from '../utils/languageTexts';
import { supportedLanguages } from '../../i18n';

class Bio extends React.Component {
  render() {
    const { langKey } = this.props;

    return (
      <div
        style={{
          display: 'flex',
          marginBottom: rhythm(2),
        }}
      >
        <img
          src={profilePic}
          alt={`Hüseyin Küçük`}
          style={{
            marginRight: rhythm(1 / 2),
            marginBottom: 0,
            width: rhythm(2),
            height: rhythm(2),
            borderRadius: '50%',
          }}
        />
        <p style={{ maxWidth: 500 }}>
          <a href="https://www.hkucuk.com.tr">Hüseyin Küçük</a>
          {languageTexts[langKey]['personal_blog']}
          <br></br>
          {languageTexts[langKey]['software_engineer']}{' '}
          <a href="https://www.masomo.com" target="_blank">
            @Masomo
          </a>{' '}
          <br></br>
          {Object.keys(supportedLanguages).map((lang, index) =>
            lang === langKey ? null : (
              <React.Fragment key={lang}>
                <a href={lang === 'tr' ? '/' : `/${lang}`}>
                  {supportedLanguages[lang]}
                </a>
                {index !== Object.keys(supportedLanguages).length - 1 && ' • '}
              </React.Fragment>
            )
          )}
        </p>
      </div>
    );
  }
}

export default Bio;
